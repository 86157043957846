import $ from 'jquery';
import SumoSelect from 'sumoselect';
import observe from '../../js/utilities/observer';

// What the fack is wrong with sumo-select
window.sels = '';
window.i = 0;

const CLASS_SUMO = 'sumo-select';

export class Sumo {
    /**
     * constructor of the Sumo
     * @return {*}
     */
    constructor() {
        $('.sumo-select').SumoSelect({
            triggerChangeCombined: true,
            placeholder: 'Select'
        });

        // Hack for placeholder
        $('.SumoSelect .optWrapper ul li:first-child label').each((key, element) => {
            if (!$(element).text()) {
                $(element)
                    .parent()
                    .remove();
            }
        });
    }
}

observe(`.${CLASS_SUMO}`, () => {
    new Sumo();
});
