export function closest(node, selector) {
    if (node.closest) return node.closest(selector);

    while (node && node !== document.documentElement) {
        if (matchesSelector(node, selector)) return node;
        if (!node.parentNode) return null;

        node = node.parentNode;
    }

    return null;
}

export function createCustomEvent(name, detail, bubbles = true, cancelable = false) {
    if (!name || !detail) {
        throw new Error('Creating custom event without a name or detail!');
    }

    if (typeof window.CustomEvent === 'function') {
        return new CustomEvent(name, {
            bubbles,
            detail
        });
    }

    // fallback for IE
    const customEvent = document.createEvent('CustomEvent');
    customEvent.initCustomEvent(name, bubbles, cancelable, detail);

    return customEvent;
}

export function fireEvent(name, node, detail = {}) {
    detail.type = detail.type || 'custom';
    const element = node || document.body;

    const customEvent = createCustomEvent(name, detail, true, true);

    if (customEvent) {
        element.dispatchEvent(customEvent);
    }
}

export function confirm(node) {
    const dataMessage = node.hasAttribute('data-message')
        ? node.getAttribute('data-message')
        : null;
    const message = dataMessage ? `${dataMessage}\n\nAre you sure?` : 'Are you sure?';

    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(message);
    return isConfirmed;
}

export function serialize(form) {
    let field;
    let l;
    const s = [];

    const elements = Array.from(form.querySelectorAll('input, select, textarea'));
    const len = elements.length;

    for (let i = 0; i < len; i += 1) {
        field = elements[i];

        if (
            field.name &&
            !field.disabled &&
            field.type !== 'button' &&
            field.type !== 'file' &&
            field.type !== 'reset' &&
            field.type !== 'submit'
        ) {
            if (field.type === 'select-multiple') {
                l = elements[i].options.length;

                for (let j = 0; j < l; j += 1) {
                    if (field.options[j].selected) {
                        s[s.length] = `${encodeURIComponent(field.name)}=${encodeURIComponent(
                            field.options[j].value
                        )}`;
                    }
                }
            } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                s[s.length] = `${encodeURIComponent(field.name)}=${encodeURIComponent(
                    field.value
                )}`;
            }
        }
    }

    return s.join('&').replace(/%20/g, '+');
}

export function delay(callback, ms) {
    if (typeof ms === 'undefined') ms = 0;
    setTimeout(callback, ms);
}

export function createDataAttributes(object) {
    const dataAttributes = {};
    if (!object) return dataAttributes;
    Object.keys(object).forEach((key) => {
        dataAttributes[`data-${key}`] = object[key];
    });

    return dataAttributes;
}

export const continuousPromise = (promiseFactory, interval) => {
    const execute = () => promiseFactory().finally(waitAndExecute);
    const waitAndExecute = () => window.setTimeout(execute, interval);
    execute();
};

export const isNumber = (value) => {
    // We will not coerce boolean to numbers, although we could.
    // We will not coerce strings to numbers, even though we could try.
    // Referencing https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
    if (typeof value !== 'number') {
        return false;
    }

    // Consider this as the NaN check.
    // NaN is a number.
    // NaN has the unique property of never equaling itself.
    // Pulled this hack right off of MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN
    if (value !== Number(value)) {
        return false;
    }

    // At this point, we for sure have some sort of number.
    // But not all numbers are finite, and realistically we want finite numbers.
    if (Number.isFinite(value) === false) {
        return false;
    }

    return true;
};

export const createElement = (type, attributes, ...children) => {
    const element = document.createElement(type);

    Object.entries(attributes).forEach(([attribute, value]) => {
        element.setAttribute(attribute, value);
    });

    children.forEach((child) => {
        element.appendChild(typeof child === 'string' ? document.createTextNode(child) : child);
    });

    return element;
};

export const debounce = (callback, wait) => {
    let timeout = null;
    return (...args) => {
        const next = () => callback(...args);
        clearTimeout(timeout);
        timeout = setTimeout(next, wait);
    };
};

export const humanFileSize = (bytes, si) => {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
};
