export class Csrf {
    static init() {
        new Csrf();
    }

    /**
     * constructor of the Ping
     * @return {*}
     */
    constructor() {
        const self = this;
        
        document.addEventListener('DOMContentLoaded', () => {
            setInterval(() => {
                self.pulse();
            }, 1800000);
        });
    }

    /**
     * Determine the timezone and send a request to check
     * if there are some new updates
     * if so add class to the notifications to show the user
     * @return {*}
     */
    pulse() {
        $.ajax({
            type: 'POST',
            url: '/helpers/ping/csrf',
            data: { 
                token: token,
            },
            timeout: 10000,
            success(data) {
                document.cookie = data.cookie;

                token = data.token;
                $('input[name="token"]').val(data.token);
            },
            dataType: 'json',
            async: true
        });
    }
}

Csrf.init();
