/* eslint-disable */
import validate from 'jquery-validation';
import observe from '@utils/observer';

import 'modules/jquery-ui/ui/effect';

// Temp fix
jQuery.validator.setDefaults({
    // This will ignore all hidden elements alongside `contenteditable` elements
    // that have no `name` attribute
    ignore: ":hidden, [contenteditable='true']:not([name]), .wysiwyg"
});

var step_count = 1, step_count_max, form_to_validate, step_state;

var add_steps = {

    init: function() {
        add_steps.set_up();
        add_steps.select_first();
        add_steps.general_clicks();
        add_steps.toggle_step();

        //Init validator
        form_to_validate = $(".js-validate");
        if (form_to_validate.length > 0) {
            form_to_validate.validate({
                errorClass: "has-error"
            });
        }
    },

    set_up: function() {
        $('.c-steps .c-steps__item').each(function(index) {
            $(this).attr('data-step-count', index+1);
        });
        step_state = $('.c-steps').attr('data-state');
    },

    select_first: function() {
        step_count_max = $('.c-steps .c-steps__item').length;
        if (step_state !== 'edit' && step_state !== 'checklist') {
            $('.c-steps__item[data-step-count="' + step_count + '"]').attr('data-step-access', 'open')
                .addClass('is-active')
                .find('article')
                .slideDown(600, 'easeOutExpo');
        }
    },

    general_clicks: function() {

        if (step_state !== 'edit') {
            $('.c-steps__item header').off().on('click', function(e) {
                if (e.target.tagName !== 'A') {
                    e.preventDefault();
                    var parent_el = $(this).closest('.c-steps__item');
                    step_count = parent_el.attr('data-step-count');

                    if ( parent_el.attr('data-step-access') == 'open' && !$('.c-steps__item[data-step-count="' + step_count + '"]').hasClass('is-active') ) {
                        add_steps.select_step(step_count, false);
                    }
                }
            });
        } else {
            $('.c-steps__item header .button').off().on('click', function(e) {
                e.preventDefault();
                var parent_el = $(this).closest('.c-steps__item');
                step_count = parent_el.attr('data-step-count');

                if (parent_el.attr('data-step-access') == 'open') {
                    add_steps.toggle_step(step_count);
                }
            });
        }

        $('.c-steps__item footer input[type=checkbox]').off().on('change', function(e) {
            var parent_el = $(this).closest('.c-steps__item');
            var current_count = parent_el.attr('data-step-count');

            current_count++;
            if ( !parent_el.find('.step-check').hasClass('checked') ) {
                parent_el.find('.step-check').addClass('checked');
            }
            add_steps.select_step(current_count, 'next');
        });

        $('body').off().on('click', '.next-step', function(e) {
            e.preventDefault();
            var parent_el = $(this).closest('.c-steps__item');
            var current_count = parent_el.attr('data-step-count');

            // If need recipients
            if(parent_el.find('#list-recipients').length) {
                var has_recipient = $('#list-recipients').find('.recipient').length;
                if( !has_recipient ) {
                    parent_el.find('.txt--red').remove();
                    parent_el.find('footer .float-right').prepend('<span class="txt--red">' + $('#list-recipients').data('msg') + '</span>');
                    return false;
                }
            }

            // If need recipients
            if(parent_el.find('#request-recipients').length) {
                var has_recipient = $('#request-recipients').find('.recipient').length;
                if( !has_recipient ) {
                    parent_el.find('.txt--red').remove();
                    parent_el.find('footer .float-right').prepend('<span class="txt--red">' + $('#request-recipients').data('msg') + '</span>');
                    return false;
                }
            }

            // If need accreditation
            if(parent_el.find('#event-dates').length) {
                var has_event_dates = $('#event-dates').find('input[type="checkbox"]:checked').length;
                if( !has_event_dates ){
                    $('#event-dates').find('.f-con__error').remove();
                    $('#event-dates').append('<div class="f-con__error"><span class="txt--red">' + $('#event-dates').data('msg') + '</span></div>');
                    return false;
                }
            }

            // If need accreditation
            if(parent_el.find('#accreditations').length) {
                var has_accreditation_limits = parent_el.find('#accreditations').find('input[type="checkbox"]:checked').length;
                var has_accreditations = $('#accreditations input[type="number"]').filter(function() { return $(this).val(); }).length;

                if( !(has_accreditation_limits || has_accreditations) && $('#no_general_bulk:checked').length == 0 ){
                    parent_el.find('#accreditations').find('.f-con__error').remove();
                    parent_el.find('#accreditations').append('<div class="f-con__error"><span class="txt--red">' + $('#accreditations').data('msg') + '</span></div>');
                    return false;
                }
            }

            // If need accreditation
            if(parent_el.find('#section-selector').length) {
                var has_sections = $('#section-selector').find('input[type="checkbox"]:checked').length;
                if( !has_sections ){
                    $('#section-selector').find('.f-con__error').remove();
                    $('#section-selector').append('<div class="f-con__error"><span class="txt--red">' + $('#section-selector').data('msg') + '</span></div>');
                    return false;
                }
            }

            // Check if form validate
            if (form_to_validate.length > 0) {
                if(form_to_validate.valid()) {

                    current_count++;

                    if ( !parent_el.find('.step-check').hasClass('checked') ) {
                        parent_el.find('.step-check').addClass('checked');
                    }

                    add_steps.select_step(current_count, 'next');

                }
            }
        });

        $('.prev-step').off().on('click', function(e) {
            e.preventDefault();
            var parent_el = $(this).closest('.c-steps__item');
            var current_count = parent_el.attr('data-step-count');

            current_count--;
            add_steps.select_step(current_count, 'prev');

        });

    },

    select_step: function(step_count, direction = false) {

        $('.c-steps__item.is-active')
            .removeClass('is-active')
            .find('article')
            .slideUp(600, 'easeOutExpo');

        var next_step_is = $('.c-steps__item[data-step-count="' + step_count + '"]');

        if( next_step_is.hasClass("hidden") ) {

            if(direction == 'next' ){
                step_count = next_step_is.nextAll('.c-steps__item:visible').first().attr('data-step-count');
            }
            if(direction == 'prev' ){
                step_count = next_step_is.prevAll('.c-steps__item:visible').first().attr('data-step-count');
            }
        }

        $('.c-steps__item[data-step-count="' + step_count + '"]')
                .attr('data-step-access', 'open')
                .addClass('is-active')
                .find('article')
                .slideDown(600, 'easeOutExpo');
    },

    toggle_step: function(step_count) {

        var next_step_is = $('.c-steps__item[data-step-count="' + step_count + '"]');

        if(next_step_is.hasClass('is-active')) {

            $('.c-steps__item[data-step-count="' + step_count + '"]')
                .removeClass('is-active')
                .find('article')
                .slideUp(600, 'easeOutExpo');

        } else {

            $('.c-steps__item[data-step-count="' + step_count + '"]')
                .addClass('is-active')
                .find('article')
                .slideDown(600, 'easeOutExpo');

        }

    }
};

observe('.c-steps', () => {

    add_steps.init();

});
