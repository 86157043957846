import jstz from 'jstz';

export class Ping {
    static init() {
        new Ping();
    }

    /**
     * constructor of the Ping
     * @return {*}
     */
    constructor() {
        const self = this;
        const env = window.location.host.split('.')[2];

        if (env === 'local' || env === 'dev') return;

        document.addEventListener('DOMContentLoaded', () => {
            if (!document.body.classList.contains('user-logged-in')) return;

            setInterval(() => {
                self.pulse();
            }, 10000);
        });
    }

    /**
     * Determine the timezone and send a request to check
     * if there are some new updates
     * if so add class to the notifications to show the user
     * @return {*}
     */
    pulse() {
        const timezone = jstz.determine();

        $.ajax({
            type: 'POST',
            url: '/helpers/ping',
            data: { 
                token: token,
                timezone: timezone.name() 
            },
            timeout: 1000,
            success(data) {
                if (data.status) {
                    if (data.notifications) {
                        $('.notifications-hook').addClass('youve-got-mail');
                    }
                } else {
                    ajax_actions.handle_response(data);
                }
            },
            dataType: 'json',
            async: true
        });
    }
}

Ping.init();
