import Modal from '@components/modal/modal';

$(document).ready(function() {
	event_user.init();
});

var event_user = {
	init : function() {
		event_user.save_template();
		event_user.load_template();
	},
	save_template : function() {
		$('#add-accreditation-template').on('click', function(e) {
			e.preventDefault();

			var $this = $(this);

			var items = {};
			$('#event-dates input:checked').each( function() {
				if( $(this).attr('name') )
					items[$(this).attr('id')] = true;
			});

			$('#accreditations input').each( function() {

				if( $(this).val() && $(this).attr('type') == 'text' || $(this).val() && $(this).attr('type') == 'number' ) {
					items[$(this).attr('name')] = $(this).val();
				}
				if( $(this).prop('checked') && $(this).attr('type') == 'checkbox' ) {
					items[$(this).attr('name')] = true;
				}
			});

			$.ajax({
				type: 'POST',
				url: $this.attr('href'),
				xhr: function() {
					var xhr = jQuery.ajaxSettings.xhr();
					var setRequestHeader = xhr.setRequestHeader;
					xhr.setRequestHeader = function(name, value) {
						if (name == 'X-Requested-With') return;
						setRequestHeader.call(this, name, value);
	        		};
					return xhr;
	    		},
				data: { 
					token: token,
					template_settings: JSON.stringify(items), 
					template_type: $this.data('type') 
				},
				success: function(data) {
					if( data.success ) {

						const modal = new Modal();
						modal.showHtml(data.html, { backdrop: true, keyboard: true });

					}
				},
				dataType: 'json',
				async: true
			});
		})
	},

	load_template : function() {
		$('#select-accreditation-template').on('change', function(e) {

			var $this = $(this);

			$('.template-inputs').find('input[type="number"]').val('').attr('placeholder', '').removeClass('active');
			$('.template-inputs').find('input[type="checkbox"]').prop('checked', false);

			if( $this.val() ) {

				$.ajax({
					type: 'POST',
					url: $this.data('href'),
					data: { 
						token: token,
						template_id: $this.val() 
					},
					success: function(data) {
						if( data.success ) {

							const checkboxes = Array.from(document.querySelectorAll('#event-dates input[type=checkbox]'));
							checkboxes.forEach((check) => {
								check.checked  = false;
								const changeEvent = new Event('change');
								check.dispatchEvent(changeEvent);
							});

							$('.accreditation-item').find('input[type=number]').val('');
							$('.accreditation-item').find('input[type=checkbox]').prop('checked', false).trigger('change').hide();

							var template = $.parseJSON(data.template);
							$.each( template, function( name, value ) {

								if( name.includes('event-date-') ) {

									let eventDateCheckboxes = Array.from(document.querySelectorAll(`#${name}[type="checkbox"]`));
									eventDateCheckboxes.forEach((check) => {
										check.checked  = true;
										const changeEvent = new Event('change');
										check.dispatchEvent(changeEvent);
									});

								} else if( name.includes('event_accreditations') || name.includes('accreditation_limits') ) {

									if( value ) {

										var $form_input = $('body').find('input[name="' + name + '"][type="number"]');
										$form_input.val( value ).addClass('active');

										var $form_checkbox = $('body').find('input[name="' + name + '"][type="checkbox"]').show();
										$form_checkbox.prop('checked', true).trigger('change');


										// $form_checkbox = $('#accreditations').find('input[name="' + name + '"][type="checkbox"]').trigger('change');
										// if( $form_input = $form_checkbox.siblings('input[type="number"]') ) {
										// 	$form_checkbox.prop('checked', true );
										// 	$form_input.addClass('active');
										// 	if( name == 'event_accreditation_limits' )
										// 		$form_input.attr('placeholder', '\u221e');
										// }
									}
									// else if ( value ) {
									// 	console.log( name + ': ' + value)
									// 	$form_input = $('#accreditations').find('input[name="' + name + '"][type="number"]');
									// 	$form_checkbox = $form_input.siblings('input[type="checkbox"]');
									// 	$form_checkbox.prop('checked', true );
									// 	$form_input.val( value ).addClass('active');
									// 	if( name == 'event_accreditation_limits' )
									// 		$form_input.attr('placeholder', '\u221e');
									// }
								}

								$('.event-date-select').trigger('change');
							});
						}
					},
					dataType: 'json',
					async: true
				});
			} else {
				$('.event-date-select').trigger('change');
			}
		});
	}
}
