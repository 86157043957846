import { delay, closest, createElement } from '@utils/utilities';

const SNACKBAR_LIST_CLASS = 'snackbar__list';
const SNACKBAR_TEXT_CLASS = 'snackbar__text';
const SNACKBAR_ITEM_CLASS = 'snackbar__item fade-in-up is-shown';
const SNACKBAR_FADE_OUT_TIME = 5000;
const SNACKBAR_DELETE_TIME = 300;

export class Snackbar {
    constructor() {
        this.list = document.querySelector(`.${SNACKBAR_LIST_CLASS}`);
        this.deleteSnackbar = this.deleteSnackbar.bind(this);
    }

    removeSnack(el) {
        el.classList.remove('is-shown');
        this.deleteSnackbar(el);
    }

    closeSnackbar(e, timeOut) {
        e.target.removeEventListener('click', this.closeSnackbar);
        const target = closest(e.target, 'li');
        target.classList.remove('is-shown');

        clearTimeout(timeOut);
        this.deleteSnackbar(target);
    }

    deleteSnackbar(el) {
        delay(() => {
            el.parentNode.removeChild(el);
        }, SNACKBAR_DELETE_TIME);
    }

    addSnack(text, type) {
        const snackbarText = createElement('span', { class: SNACKBAR_TEXT_CLASS }, text);
        const snackbarClose = createElement(
            'div',
            { class: 'snackbar__close' },
            createElement('i', { class: 'material-icons-round' }, 'close')
        );
        const snackbarIcon = createElement(
            'i',
            {
                class: `material-icons-round ${
                    type === 'success' ? 'text-green-100' : 'text-orange-100'
                }`
            },
            `${type === 'success' ? 'check_circle' : 'warning'}`
        );
        const snackbarElement = createElement(
            'li',
            { class: SNACKBAR_ITEM_CLASS },
            snackbarIcon,
            snackbarText,
            snackbarClose
        );

        const timeOut = setTimeout(() => {
            this.removeSnack(snackbarElement);
        }, SNACKBAR_FADE_OUT_TIME);

        snackbarClose.addEventListener('click', (e) => this.closeSnackbar(e, timeOut));
        this.list.insertBefore(snackbarElement, this.list.firstChild || null);
    }
}

export default Snackbar;
