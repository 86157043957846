/*
    Ajax action
    This is used to handle all ajax actions.
    Depending on what the backend gives as JSON ajax actions will handle it.
*/

import { fireEvent, delay } from './utilities';
import Snackbar from '@components/snackbar/snackbar';

let timer = null;
const snackbar = new Snackbar();

window.ajax_actions = {

    handle_response: function( data, element, button ) {

        if( typeof button === 'undefined' ) button = false;

        if( typeof xhr_autocomplete !== 'undefined' ) xhr_autocomplete.abort();

        $("body").css("cursor", "default");

        if (element) {
            /* ===	Ajax Forms				=================================================	*/
            element.find('input, button, select, textarea').removeAttr('readonly').trigger("chosen:updated");
            element.find('input[type="submit"], button').removeAttr('disabled');

            if( element.is('form') || element.hasClass('inline-ajax-form') ) {

                if(button) {
                    delay( function() {
                        button.find('.icn').remove();
                    }, 1000);
                }

                /* ===	Form Validation		=================================================	*/
                element.find('.has-error').removeClass('has-error');
                element.find('.input-error').removeClass('input-error');

                if( data.success ) {
                    // element.find('.ajax-form-notification').removeClass('error').removeClass('show');


                } else {
                    if( data.error_message ) {
                        snackbar.addSnack(data.error_message, 'error');
                    }

                    if( data.error_fields ) {
                        $.each(data.error_fields, function(key,value){
                            element.find('[name^="' + key + '"]').addClass('has-error');
                            element.find('[name^="' + key + '"]').addClass('input-error');
                        });

                    if( $('.error:first').length ) {
                            $('html, body').animate({
                                scrollTop: $('body').find('.input-error:first').offset().top - 150
                            }, 500);
                    }
                    }
                }

                /* ===	Form Actions		=================================================	*/

                if( data.refresh_form ) {
                    element.html(data.html);
                }

                if( data.clear_form ) {
                    element.find('input:not([type="submit"], [type="radio"], [type="hidden"]), textarea, select, button').val('').removeAttr('readonly');
                }

                if( data.leave_protection ) {
                    window.onbeforeunload = function() {
                        return "Are you sure?";
                    };
                }

                if( data.dismiss_leave_protection ) {
                    window.onbeforeunload = null;
                }

            }
        }

        /* ===	Notification Actions	=================================================	*/

        if( data.notification ) {

            element.find('.ajax-form-notification').html( data.notification ).addClass('success show');
            element.closest('.modal-section').find('.ajax-form-notification').html( data.notification ).addClass('success show');

            delay( function() {
                $('.ajax-form-notification').removeClass('success show');
            }, 3000 );
        }

        if( data.snackbar ) {
            snackbar.addSnack(data.snackbar.text, data.snackbar.type);
        }

        /* ===	Document Actions		=================================================	*/

        if( data.refresh ) {
            delay( function() {
                location.reload();
            }, data.delay);
        }
        if( data.redirect ) {
            delay( function() {
                window.location = data.redirect;
            }, data.delay);
        }

        /* ===	Table Actions		=================================================	*/

        if( data.table_refresh ) {

            var datatable = $('body').find('table.dataTable').each( function() {
                $(this).DataTable().draw();
            });

            if( typeof event_user_list_table !== 'undefined' ) event_user_list.reload_data();
        }

        if( data.close_multi_actions ) {
            //TODO: baba
            $('.c-multiactions').removeClass('is-active');
        }

        /* ===	Modal Actions		=================================================	*/
        if (data.modal) {

            const modal = $('#modal');

            modal.html('');
            modal.html(data.modal);
            modal.modal({ backdrop: 'static', keyboard: true });
            modal.modal('show');
        }

        if( data.notification_modal ) {
            var modal = $('#modal');
            modal.html('');
            modal.html(data.notification_modal);
            modal.modal({ backdrop: 'static', keyboard: false });
            modal.modal("show");
        }

        if(data.close_modal) {

            if (timer) clearTimeout(timer);

            timer = setTimeout(() => {

                const modal = $('#modal');
                modal.modal('hide');

            }, data.delay);

        }

        /* ===	Display Actions		=================================================	*/

        if( data.hide_this ) {
            $(element).hide();
        }

        if( data.hide_element ) {
            $(data.hide_element).hide();
        }

        if( data.show_element ) {
            $(data.show_element).show();
        }
        if( data.add_class ) {
            $(data.add_class.target ).addClass(data.add_class.the_class);
        }

        if( data.remove_class ) {
            $(data.remove_class.target ).removeClass(data.remove_class.the_class);
        }

        if( data.hide_popover ) {
            element.closest('.hide-on-click').removeClass('show');
        }

        if( data.delete_element ) {
            $(data.delete_element).remove();
        }

        if( data.hide_element ) {
            $(data.hide_element).hide();
        }

        if( data.delete_row ) {
            $(element).closest('tr').fadeOut(400, function() {
                $(element).closest('tr').remove()
            });
        }

        if( data.prepend_to ) {
            $(data.prepend_to.target).prepend(data.prepend_to.html);
        }

        if( data.append_to ) {
            $(data.append_to.html).appendTo($(data.append_to.target));
        }

        if( data.insert_before ) {
            $(data.insert_before.html).insertBefore($(data.insert_before.target));
        }

        if( data.set_html ) {
            $(data.set_html.target).html(data.set_html.html);
        }

        if( data.replace_html ) {
            $(data.replace_html.target).replaceWith(data.replace_html.html);
        }

        if( data.set_value ) {
            $(data.set_value.target).val(data.set_value.value).trigger('chosen:update');
        }

        if( data.set_values ) {

            for (var i in data.set_values) {

                $(data.set_values[i].target).val(data.set_values[i].value).trigger('chosen:update');

                fireEvent('ajax:input', $(data.set_values[i].target)[0], { type: 'change' });

            }

        }

        if( data.refresh_timetable_events ) {

            if ($.fn.fullCalendar) {

                $('.timetable').fullCalendar( 'refetchEvents' );

            }

        }

        if( data.refresh_timetable_sources ) {
            $('.timetable').fullCalendar( 'refetchResources' );
        }

        if( data.to_top ) {
            window.scrollTo(0, 0);
        }

        if( data.scroll_to ) {
            $('.o-scroll').animate({
                scrollTop: $(data.scroll_to).offset().top
            }, 500);
        }

        if( data.update_order ) {
            window.creator.set_order();
        }

        if( data.briefing_reinit ) {
            window.creator.init();
            console.log('call sort');
        }

        if( data.autocomplete ) {
            $(element).append( data.autocomplete );
        }

        /* ===	Element Actions		=================================================	*/

        if( data.check_element ) {
            $(element).prop('checked', true);
        }

        if( data.uncheck_element ) {
            $(element).prop('checked', false);
        }

        if( data.set_element_value ) {
            $(element).val(data.set_element_value).trigger("chosen:updated");
        }

        if( data.replace_element ) {
            $(element).replaceWith(data.replace_element);
        }

        if( data.populate_form ) {
            for( var i in data.populate_form ) {
                $('[name="'+i+'"]').val(data.populate_form[i]);
            }
        }

        /* ===	Callback 			=================================================	*/


        if( data.callback ) {
            var namespace 	  = data.callback.namespace;
            var function_name = data.callback.function;

            if( typeof namespace !== 'undefined' ) {
                window[namespace][function_name](data.callback.arguments);
            } else {
                window[function_name](data.callback.arguments);
            }
        }

        // Single event
        if (data.fireEvent) {

            const node = document.querySelector(data.fireEvent.selector);
            fireEvent(data.fireEvent.name, node, data.fireEvent.detail);

        }

        // Array of events
        if (data.fireEvents) {

            data.fireEvents.forEach((event) => {

                const node = document.querySelector(event.selector);
                fireEvent(event.name, node, event.detail);

            });

        }

        if( data.load_modal ) {
            var modal = modal = $('#modal');
    		modal.html('');

            data.token = token;

    		$.ajax({
    			url: data.load_modal,
    			type: 'POST',
    			data: data,
    			xhr: function() {
    				var xhr = jQuery.ajaxSettings.xhr();
    				var setRequestHeader = xhr.setRequestHeader;
    				xhr.setRequestHeader = function(name, value) {
    					if (name == 'X-Requested-With') value = 'i2e-modal';
    					setRequestHeader.call(this, name, value);
            		};
    				return xhr;
        		},
    			success: function(data, textStatus, jqXHR) {
    				modal.html(data);
    				modal.modal({ backdrop: 'static', keyboard: true });
    				modal.modal("show");

        		}
    		});
        }

        /* ===	React		=================================================	*/

        if(data.react_table_refresh) {

            console.log(window.tableInstances, data.react_table_refresh);

            if(!window.tableInstances || !window.tableInstances[data.react_table_refresh]) return;

            window.tableInstances[data.react_table_refresh].request();

        }
    }
};
