import observe from '@utils/observer';

const CLASS_SHOW_OVERLAY = 'show-overlay';
const CLASS_HIDE_OVERLAY = 'c-overlay-dialog__close';

export class Overlay {
    static init() {
        new Overlay();
    }

    /**
     * constructor of the Overlay
     * @return {*}
     */
    constructor() {
        const showOverlay = (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                this.show(trigger.dataset.target);
            });
        };

        observe(`.${CLASS_SHOW_OVERLAY}`, showOverlay);

        const hideOverlay = (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();
                this.hide(trigger);
            });
        };

        observe(`.${CLASS_HIDE_OVERLAY}`, hideOverlay);
    }

    /**
     * [show description]
     * @param  {[type]} element [description]
     * @return {[type]}         [description]
     */
    show(element) {
        const overlay = document.getElementById(element);
        overlay.style.display = 'block';

        const barcodeInput = `#${element} .connect-custom-uid`;

        document.querySelector(barcodeInput).value = '';
        document.querySelector(barcodeInput).focus();
    }

    hide(element) {
        const overlay = element.closest('.c-overlay');
        overlay.style.display = 'none';
    }
}

Overlay.init();
