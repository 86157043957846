import 'modules/jquery-ui/ui/widgets/draggable';
import 'modules/jquery-ui/ui/widgets/droppable';
import 'modules/jquery-ui/ui/widgets/sortable';

/*
| -------------------------------------------------------------------
|  Event Briefing creator Javascript
| -------------------------------------------------------------------
|
| 1. DOCUMENT READY
| 2. CREATOR INIT
| 3. SORTABLE / DRAGGABLE
| 4. BUILDER
| 5. AUTOSAVE
|
*/


/*
| -------------------------------------------------------------------
|  1. DOCUMENT READY
| -------------------------------------------------------------------
*/

$(document).ready( function() {
	window.creator.init();
});

window.creator = {
	base_url: $('#template-body').data('base-url'),

	/*
	| -------------------------------------------------------------------
	|  2. CREATOR INIT
	| -------------------------------------------------------------------
	*/

	init : function(){

		var editor;
		window.creator.cancel();
		window.creator.set_order();
		window.creator.sortable();
		window.creator.edit();
		window.creator.builder();
//		creator.remove_item();
		window.creator.block_actions();
//		creator.save_exit();
		window.creator.set_colors();
		window.creator.saveAction();
	},


	/*
	| -------------------------------------------------------------------
	|  3. ENABLE THE TEXT EDITOR
	| -------------------------------------------------------------------
	*/

	set_colors : function() {

		$('#template-wrapper').css({'background-color' : '#' +  $('#template_background_color').val() });

		$('#template_background_color').on('change', function(e) {
			$('#template-wrapper').css({'background-color' : '#' +  $(this).val() });
		});

	},

	sortable : function(){

		$('#template-body #dropzone').sortable({
            cancel: true,
			axis: 'y',
		    handle: '.sortable-handle',
		    tolerance: 'pointer',
		    cursor: 'move',
		    // containment: '#dropzone',
		    opacity: 1,
		    forcePlaceholderSize: true,
		    cursorAt: {top: 1},
			revert: true,
            start: function(e, ui){

				//TODO: deze moet anders
                ui.item.height(ui.item.height() + 48);
            },
			update: function(event, ui) {
				var $item = ui.item;

				creator.sortable();

				if( $item.hasClass('bc-block-drag') ) {
					var $view = $item.data('view');

					$.ajax({
						type: 'POST',
						url: creator.base_url + '/add_block',
						data: { 
							token: token,
							view: $view 
						},
						success: function(data) {
							if( data.success ) {

								$($item).replaceWith(data.html);

								creator.update_order();
								creator.set_order();

								if( $('#dropzone').find('.empty-state').length > 0 ) {
									$('#dropzone').find('.empty-state').hide();
								}
							}
						},
						dataType: 'json',
						async: true
					});
				}

				creator.update_order();
				creator.set_order();
			}
		});
	},

	builder : function() {
		$('#template-blocks .bc-block-drag').draggable({
			connectToSortable: '#template-body #dropzone',
			helper: 'clone',
			revert: 'invalid',
			hoverClass: 'ui-state-active',
			start: function (event, ui) {
        		$('#template-body #dropzone').sortable('option', 'placeholder', 'dropblock');
				var $item = event.target;
				$($item).addClass('start-dragging');

                if( $('#dropzone').find('.empty-state').length > 0 ) {
                    $('#dropzone').find('.empty-state').hide();
                }

    		},
			over: function (event, ui) {
        		outside = false;
    		},
    		out: function (event, ui) {
        		outside = true;
    		},
    		stop: function () {
        		$('#template-body #dropzone').sortable('option', 'placeholder', null);
    		}
		});
	},

	edit : function() {

		$('body').off('click', '.edit-template-block').on('click', '.edit-template-block', function(e) {
			e.preventDefault();
			creator.close_editor();
			creator.disableSave(true);
			$(this).closest('.bc-block').find('.bc-editor').addClass('is-active');
		});
	},

    /*
    | -------------------------------------------------------------------
    |  5. CANCEL SAVE
    | -------------------------------------------------------------------
    */

    cancel : function(){

        $('body').on('click', '.bc-editor__cancel', function(e) {
			e.preventDefault();
			creator.close_editor();
			creator.disableSave(false);
        });
    },

	saveAction: function(){

        $('body').off('click', '.bc-editor__save').on('click', '.bc-editor__save', function(e) {
			creator.disableSave(false);
		});

    },


	close_editor : function() {

		creator.disableSave(false);

		$('.c-creator').find('.bc-editor').removeClass('is-active');
	},

	disableSave: function(boolean) {

		var saveButton = $('.c-header__actions .submit-ajax-form');

		if (boolean) {

			saveButton.prop('disabled', true);

		} else {

			saveButton.prop('disabled', false);

		}

	},


	/*
	| -------------------------------------------------------------------
	|  7. UPDATE ORDER
	| -------------------------------------------------------------------
	*/

	update_order : function() {
		$.ajax({
			type: 'POST',
			url: creator.base_url + '/update_order',
			data: {
				token: token,				
				action: 'update_order',
				ordering: $('#dropzone').sortable('serialize')
			},
			success: function(data) {
				ajax_actions.handle_response(data, $('#dropzone'))
			},
			dataType: 'json',
			async: true
		});
	},

	/*
	| -------------------------------------------------------------------
	|  8. SET ORDER NUMBERING
	| -------------------------------------------------------------------
	*/

	set_order: function(){
		var blocks = $('#dropzone').find('.bc-block');
		blocks.each(function( index, element ) {
			$(this).find('.bc-block__number').text(index + 2);
		});
	},


	/*
	| -------------------------------------------------------------------
	|  9. BLOCK ACTIONS
	| -------------------------------------------------------------------
	*/

	block_actions : function() {

		var editor = $('.bc-editor');

		$.each(editor, function( index, item ) {
			var content = $(item).find('.bc-editor__content');

			if(content.length === 0) {
				$(item).find('.show-settings').addClass('is-active');
				$(item).find('.show-content').remove();
			}
		});

		$('body').off('.bc-editor__tabs a').on('click', '.bc-editor__tabs a', function(e) {
			e.preventDefault();
			var $button = $(this);

            //TODO: Netjes maken
			if( $button.hasClass('show-settings') ){
                $(this).closest('.bc-editor__tabs').find('.show-content').removeClass('is-active');
                $(this).closest('.bc-block').find('.bc-editor__content').removeClass('is-open');
                $(this).closest('.bc-editor__tabs').find('.show-settings').addClass('is-active');
				$(this).closest('.bc-block').find('.bc-editor__settings').addClass('is-open');
			}else{
                $(this).closest('.bc-editor__tabs').find('.show-settings').removeClass('is-active');
                $(this).closest('.bc-block').find('.bc-editor__settings').removeClass('is-open');
                $(this).closest('.bc-editor__tabs').find('.show-content').addClass('is-active');
				$(this).closest('.bc-block').find('.bc-editor__content').addClass('is-open');
			}
		});
	}
}
