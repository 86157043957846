import { logException } from '@utils/raven';

const COMPONENT_SELECTOR = '[data-component]:not([hidden]):not(.hidden)';
const COMPONENT_DATA = 'data-component';

export default class Core {
    constructor(components) {
        this.components = components;
        this.onMutationsObserved = this.onMutationsObserved.bind(this);
    }

    init() {
        let observer;
        const doc = window.document;
        const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

        if (MutationObserver) {
            // Watch for changes in the document
            observer = new MutationObserver(this.onMutationsObserved);
            observer.observe(doc.documentElement, {
                childList: true,
                subtree: true,
                removedNodes: true
            });
        } else {
            // fallback code for IE10
            this.nodeInsertedListener = (e) => this.checkForCandidate(e.target);
            document.body.addEventListener('DOMNodeInserted', this.nodeInsertedListener, false);
        }
    }

    scan(node = document.body) {
        const componentNodes = [...node.querySelectorAll(COMPONENT_SELECTOR)];
        this.prosessCandidates(componentNodes);
    }

    onMutationsObserved(mutations) {
        mutations.forEach((mutation) => {
            if (!mutation.addedNodes.length) return;

            [...mutation.addedNodes].forEach((node) => {
                this.checkForCandidate(node);
            });
        });
    }

    checkForCandidate(node) {
        if (node.nodeType !== Node.ELEMENT_NODE) return;

        const canditate = node.hasAttribute(COMPONENT_DATA) ? [node] : [];
        const candidates = [...node.querySelectorAll(COMPONENT_SELECTOR)].concat(canditate);

        if (candidates.length) {
            this.prosessCandidates(candidates);
        }
    }

    prosessCandidates(candidates) {
        candidates.forEach((candidate) => {
            const name = candidate.getAttribute(COMPONENT_DATA);
            this.createInstance(candidate, name);
        });
    }

    async createInstance(node, name) {
        const { components } = this;
        if (!(name in components)) return;

        const component = components[name];

        try {
            const module = await component();

            if (
                module.default &&
                module.default.init &&
                typeof module.default.init === 'function'
            ) {
                module.default.init(node);
            } else {
                // eslint-disable-next-line new-cap
                const Constructor = module.default;
                new Constructor(node);
            }
        } catch (error) {
            logException(error, 'CORE: creating a new component failded');
        }
    }
}
