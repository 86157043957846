import * as Sentry from '@sentry/browser';

export function SentryInit() {
    const host = window.location.host.split('.');
    const local = host[2];

    if (local !== 'local' && local !== 'dev') {
        // Start raven before the rest
        Sentry.init({
            dsn: 'https://50699d65a6e2430c92b4ebf4a42b6b70@sentry.io/1242755',
            ignoreErrors: ['document.getElementsByClassName.ToString'],
            environment: host[1]
        });

        window.onunhandledrejection = function e(error) {
            Sentry.captureException(new Error(error.reason.reason || error.reason.message), {
                extra: {
                    type: error.type,
                    reason: JSON.stringify(error.reason)
                }
            });
        };
    }
}

export function logException(ex, context) {
    Sentry.captureException(ex, {
        extra: context
    });

    window && window.console && window.error && console.error(ex);
}
