// The jquery modal is from bootstrap
// Maybe we can load that in a better way
//
// Also i don't like the helpers init and modalTabel
// We can fix that with the observer
//
// And we need to move the scss to this folder

import observe from '@utils/observer';
import '../../js/plugins/jquery.modal.min';

const CLASS_LOAD_MODAL = 'load-modal';

export class Modal {
    static init() {
        new Modal();
    }

    /**
     * constructor of the Modal
     * @return {*}
     */
    constructor() {
        const loadModal = (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                const url = e.target.getAttribute('href');
                let data = $(e.target).data();

                if (typeof data === 'undefined') data = false;

                this.load(url, data);
            });
        };

        observe(`.${CLASS_LOAD_MODAL}`, loadModal);
    }

    /**
     * Load the html from in URL in a modal
     * @param  {String} url
     * @param  {Object} data
     * @return {*}
     */
    load(url, data) {
        if (typeof data === 'undefined') data = {};

        const modal = $('#modal');
        modal.on('shown.bs.modal', () => {
            $(document).off('focusin.modal');
        });
        modal.modal({ backdrop: 'static', keyboard: true });
        modal.modal('hide');
        modal.html('');

        data.token = token;

        $.ajax({
            url,
            data,
            type: 'POST',
            xhr: () => {
                const xhr = jQuery.ajaxSettings.xhr();
                const { setRequestHeader } = xhr;
                xhr.setRequestHeader = (name, value) => {
                    if (name === 'X-Requested-With') value = 'i2e-modal';
                    setRequestHeader.call(xhr, name, value);
                };

                return xhr;
            },
            success: (response) => {
                modal.html(response);
                modal.modal('show');

                if ($('.modal-backdrop').length > 1) {
                    $('.modal-backdrop').not(':first').remove();
                }
            }
        });
    }

    showHtml(html, options = {}) {
        if (!html) return;

        const modal = $('#modal');
        modal.html(html);
        modal.modal(options);
        modal.modal('show');
    }
}

Modal.init();

export default Modal;
