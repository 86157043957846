import observe from '@utils/observer';
import { delay } from '@utils/utilities';

const CLASS_SPOTLIGHT = 'spotlight';

export class Spotlight {
    /**
     * constructor of the Spotlight
     * @return {*}
     */
    constructor() {
        let xhr_spotlight = null;

        $('body')
            .off('input', 'input.spotlight')
            .on('input', 'input.spotlight', function() {
                var element = $(this);

                var data = element.data();
                var destination = element.attr('href')
                    ? element.attr('href')
                    : element.data('href');

                data[element.attr('name')] = element.val();
                data['token'] = token;

                if (element.val().length >= 2) {
                    delay(function() {
                        if (xhr_spotlight) xhr_spotlight.abort();
                        xhr_spotlight = $.ajax({
                            type: 'POST',
                            data: data,
                            url: destination,
                            success: function(data) {
                                $('#spotlight').remove();
                                ajax_actions.handle_response(data, element.parent());
                            },
                            dataType: 'json',
                            async: true
                        });
                    }, 200);
                } else {
                    $('#spotlight').remove();
                }
            })
            .on('blur', 'input.spotlight', function(e) {
                delay(function() {
                    $('#spotlight').remove();
                }, 500);
            });

        $('body').on('click', '#spotlight a', function(e) {
            $('input.spotlight').val('');
            $('#spotlight').remove();
        });
    }
}

observe(`.${CLASS_SPOTLIGHT}`, () => {
    new Spotlight();
});
